import CourseInfoPage from '../pages/apprentice/info/Index'

import { AttendCoursePageWrapper } from '../pages/apprentice/attend/AttentCoursePage'


/*home page*/
import HomePage from '../pages/home/HomePage'
import MyLearning from '../pages/mylearning/MyLearning'

/*creator  pages*/
import { CreateCoursePageWrapper } from '../pages/creator/createCourse/CreateCoursePage'
import ChooseOptionPage from '../pages/creator/chooseOption/ChooseOption'
import ManageCoursePage from '../pages/creator/manageCourse/ManageCoursePage'

/*admin pages*/
import ControlPanelPage from '../pages/admin/ControlPanelPage'

/*errors page*/
import GenericErrorPage from '../pages/error/GenericErrorPage'

/*loader component*/
import Loader from '../components/Common/Loader'
import { ProfileRoutesList } from './types/ProfileRoutesList';

/*correction Tasks */
import TaskListComponent from '../pages/creator/manageCourse/tasks/TaskListComponent'
import TaskDetails from '../pages/creator/manageCourse/tasks/TaskDetails';
//import TeamsConnector from './teams/TeamsConnector'
import UserDefault from './teams/UserDefault'
import { profile_Admin, profile_AdminGlobal, profile_Student, profile_Teacher } from '../util/contants/Profiles'
import { CreateCampaignPage } from '../pages/admin/campaigns/CreateCampaignPage'
import DetailsCampaign from '../pages/admin/campaigns/DetailsCampaign'
import { EditCampaignPage } from '../pages/admin/campaigns/EditCampaignPage'

// {
//     path: "/connHandTeams",
//     component: TeamsConnector
// },   

const AppProfileRoutes: ProfileRoutesList[] =
    [
        {
            profileCode: "",
            routes: [
                {
                    path: "/userDefaultTeams",
                    component: UserDefault
                },
                {
                    path: "/",
                    component: Loader
                },

                {
                    path: "*",
                    component: GenericErrorPage
                }
            ]
        },
        {
            profileCode: "handle_query_string",
            routes: [

                {
                    path: "/",
                    component: Loader
                },
                {
                    path: "*",
                    component: Loader
                }
            ]
        },
        {
            profileCode: profile_Teacher,
            routes: [
                {
                    path: "/course/:id?/create/",
                    component: CreateCoursePageWrapper,
                    props: {
                        isEdit: false
                    }
                },
                {
                    path: "/course/:id/edit/",
                    component: CreateCoursePageWrapper,
                    props: {
                        isEdit: true
                    }
                },
                {
                    path: "/home",
                    component: HomePage
                },
                {
                    path: "/manage/:page?",
                    component: ManageCoursePage
                },
                {
                    path: "/course/create/choose",
                    component: ChooseOptionPage
                },
                {
                    path: "/course/:id/info",
                    component: CourseInfoPage
                },
                {
                    path: "/course/:id?/attend",
                    component: AttendCoursePageWrapper
                },
                {
                    path: "/taskcorrection",
                    component: TaskListComponent
                },
                {
                    path: "manage/taskdetails/:id",
                    component: TaskDetails
                },
                {
                    path: "/mylearning",
                    component: MyLearning
                },
                {
                    path: "/error",
                    component: GenericErrorPage
                }
            ]
        },
        {
            profileCode: profile_Student,
            routes: [
                {
                    path: "/home",
                    component: HomePage
                },
                {
                    path: "/course/:id/info",
                    component: CourseInfoPage
                },
                {
                    path: "/course/:id?/attend",
                    component: AttendCoursePageWrapper
                },
                {
                    path: "/mylearning",
                    component: MyLearning
                },
                {
                    path: "/error",
                    component: GenericErrorPage
                }
            ]
        },
        {
            profileCode: profile_Admin,
            routes: [
                {
                    path: "/course/:id?/create/",
                    component: CreateCoursePageWrapper,
                    props: {
                        isEdit: false
                    }
                },
                {
                    path: "/course/:id/edit/",
                    component: CreateCoursePageWrapper,
                    props: {
                        isEdit: true
                    }
                },
                {
                    path: "/home",
                    component: HomePage
                },
                {
                    path: "/manage/:page?",
                    component: ManageCoursePage
                },
                {
                    path: "/controlpanel/:page?",
                    component: ControlPanelPage
                },
                {
                    path: "/campaign/create",
                    component: CreateCampaignPage
                },
                {
                    path: "/campaign/edit/:id?",
                    component: EditCampaignPage
                },
                {
                    path: "/campaign/:id?",
                    component: DetailsCampaign
                },

                {
                    path: "/course/create/choose",
                    component: ChooseOptionPage
                },
                {
                    path: "/course/:id/info",
                    component: CourseInfoPage
                },
                {
                    path: "/course/:id?/attend",
                    component: AttendCoursePageWrapper
                },
                {
                    path: "/taskcorrection",
                    component: TaskListComponent
                },
                {
                    path: "manage/taskdetails/:id",
                    component: TaskDetails
                },
                {
                    path: "/mylearning",
                    component: MyLearning
                },
                {
                    path: "/error",
                    component: GenericErrorPage
                }
            ]
        },
        {
            profileCode: profile_AdminGlobal,
            routes: [
                {
                    path: "/course/:id?/create/",
                    component: CreateCoursePageWrapper,
                    props: {
                        isEdit: false
                    }
                },
                {
                    path: "/course/:id/edit/",
                    component: CreateCoursePageWrapper,
                    props: {
                        isEdit: true
                    }
                },
                {
                    path: "/home",
                    component: HomePage
                },
                {
                    path: "/manage/:page?",
                    component: ManageCoursePage
                },
                {
                    path: "/controlpanel/:page?",
                    component: ControlPanelPage
                },
                {
                    path: "/campaign/create",
                    component: CreateCampaignPage
                },
                {
                    path: "/campaign/edit/:id?",
                    component: EditCampaignPage
                },
                {
                    path: "/campaign/:id?",
                    component: DetailsCampaign
                },

                {
                    path: "/course/create/choose",
                    component: ChooseOptionPage
                },
                {
                    path: "/course/:id/info",
                    component: CourseInfoPage
                },
                {
                    path: "/course/:id?/attend",
                    component: AttendCoursePageWrapper
                },
                {
                    path: "/taskcorrection",
                    component: TaskListComponent
                },
                {
                    path: "manage/taskdetails/:id",
                    component: TaskDetails
                },
                {
                    path: "/mylearning",
                    component: MyLearning
                },
                {
                    path: "/error",
                    component: GenericErrorPage
                }
            ]
        },
    ]

export default AppProfileRoutes;