import ApiInterface from "../interface/ApiInterface";
import { InstitutionCertificateViewModel } from "../domain/models/InstitutionCertificateViewModel";


export default class InstitutionCertificateService {
    public async getCourseInstitutionCertificate(): Promise<any> {
        return await ApiInterface.get('/InstitutionCertificate/GetCourseInstitutionCertificate', {}).then(res => {
            return res.data;
        }).catch(err => {
            console.log(err);
        });
    }
    public async getModuleInstitutionCertificate(): Promise<any> {
        return await ApiInterface.get('/InstitutionCertificate/GetModuleInstitutionCertificate', {}).then(res => {
            return res.data;
        }).catch(err => {
            console.log(err);
        });
    }
}
