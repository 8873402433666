import { Col, Row, Image, Button } from "react-bootstrap"
import styled from 'styled-components';
import { getHolderId, getUserId } from "../../../../util/UserUtil";
import { createRef, useCallback, useContext, useEffect, useRef, useState } from "react";
import CertificateDetailsViewModel from "../../../../domain/models/course/CertificateDetailsViewModel"
import ApprenticeServices from "../../../../services/ApprenticeServices"
import ChangeLessonLoader from "../../../../components/Loaders/ChangeLessonLoader"
import { useReactToPrint } from 'react-to-print';
import { AttendCourseContext } from "../../../../context/apprentice/attend/AttendCourseContext";
import { BottomText, CertificateArea, CertificateContainer } from "../AttentCoursePageStyles";
import { CertificateContent } from "./CertificateContent";

import InstitutionCertificateService from "../../../../services/InstitutionCertificateService";

const institutionCertificateService = new InstitutionCertificateService()

const apprenticeService = new ApprenticeServices();

interface CertificateCourseProps {
    isModule:boolean
}

const CertificateCoursePage = (props:CertificateCourseProps) => {

    const {
        apprenticeCourseProgress,
        currentUserModuleContentProgress,
        collapsableListOfModules,
        reRender
    } = useContext(AttendCourseContext);

    const certificateRef= useRef(null)

    const [certificateViewModel, setCertificateViewModel] = useState<CertificateDetailsViewModel>(null as any);
    const [isLoading, setIsLoading] = useState(true);
    const [customCertificate, setCustomCertificate] = useState<string>('');


    useEffect(() => {
        loadPage()
    }, [])

    useEffect(() => {
        if(certificateViewModel)
            loadCertificate()
    }, [certificateViewModel])

    async function loadCertificate(){
        if(props.isModule){
            var response = await institutionCertificateService.getModuleInstitutionCertificate()
    
            let date = new Date(certificateViewModel.endDate) 
    
            const locale = 'pt-br'
            if(response.result !== null){
                let certificate = response.result.certificate
                        .replaceAll("{nomeAluno}", certificateViewModel.apprenticeName)
                        .replaceAll("{cursoAluno}", certificateViewModel.courseTitle)
                        .replaceAll("{moduloAluno}", certificateViewModel.moduleTitle)
                        .replaceAll("{workload}", certificateViewModel.workLoad)
                        .replaceAll("{dataAluno}", date.toLocaleDateString(locale,{
                            year: 'numeric',
                            month: ('long' || 'short' || 'numeric'),
                            weekday: ('long' || 'short'),
                            day: 'numeric'
                        }))
    
                setCustomCertificate(certificate)
            }

        }else{
            var response = await institutionCertificateService.getCourseInstitutionCertificate()
    
            let date = new Date(certificateViewModel.endDate) 
    
            const locale = 'pt-br'
            if(response.result !== null){
                let certificate = response.result.certificate
                        .replaceAll("{nomeAluno}", certificateViewModel.apprenticeName)
                        .replaceAll("{cursoAluno}", certificateViewModel.courseTitle)
                        .replaceAll("{dataAluno}", date.toLocaleDateString(locale,{
                            year: 'numeric',
                            month: ('long' || 'short' || 'numeric'),
                            weekday: ('long' || 'short'),
                            day: 'numeric'
                        }))
                        .replaceAll("{workload}", certificateViewModel.workLoad)
    
    
                setCustomCertificate(certificate)
            }

        }

        setIsLoading(false);

    }
    function loadPage(){

        if(props.isModule){
            apprenticeService.getMyModuleCertificateDetails({
                courseId: apprenticeCourseProgress.courseId,
                moduleId: currentUserModuleContentProgress.moduleId,
                apprenticeId: apprenticeCourseProgress.apprenticeId
            }).then(response =>{    
                let collapsableModule = collapsableListOfModules.list.filter(module =>{
                    return module.item.id === currentUserModuleContentProgress.moduleId
                })                
                let currentModule = collapsableModule[0].item

                let certificateDetails:CertificateDetailsViewModel = {
                    apprenticeName: response.result.apprenticeName,
                    courseTitle: response.result.courseTitle,
                    moduleTitle:currentModule.title,
                    endDate: response.result.endDate,
                    workLoad: response.result.workLoad,
                    partner: response.result.partner,
                    institutionName: response.result.institutionName
                }
                
                setCertificateViewModel(certificateDetails)


            }).catch(err => {            
                setIsLoading(false);
            });
        }else{
            apprenticeService.getMyCourseCertificateDetails({
                courseId: apprenticeCourseProgress.courseId,
                apprenticeId: apprenticeCourseProgress.apprenticeId
            }).then(response =>{          
                setCertificateViewModel(response.result)

            }).catch(err => {            
                setIsLoading(false);
            });
        }
    }

    useEffect(() => {
        reRender()
    }, [certificateViewModel]);
    
    const reactToPrintContent = useCallback(() => {
        return certificateRef.current;
      }, [certificateRef.current]);

    const print = useReactToPrint(
        {
            content:reactToPrintContent
        }
    )

    return (<>   
        {
            isLoading ?
            (
                <ChangeLessonLoader
                    sideMenuIsOpen={false}
                />
            )
            :
            (
                <CertificateContainer className="px-0">
                    <CertificateArea className="d-none d-md-block px-5">
                        <CertificateContent
                            ref={certificateRef}
                            certificateDetails={certificateViewModel}
                            holderId={getHolderId()}
                            isModule={props.isModule}
                            customCertificate={customCertificate}

                        />
                    </CertificateArea>

                    {props.isModule?<section>
                        <section onClick={()=>print!()} className="d-block d-md-none hold-download-mobile">
                        <img src={"../../../../assets/certificado-modulo-mobile-v2.png"} />
                    </section>
                    </section>:<section>
                    <section onClick={()=>print!()} className="d-block d-md-none hold-download-mobile">
                        <img src={"./../../../../assets/certificado-curso-mobile-v2.png"} />
                    </section>
                    </section>}
                    <Row className="download">
                        <Col md="auto" className="ml-auto my-auto">
                            <Button onClick={()=>print!()}>
                                <i className="bi bi-download"/>
                            </Button>
                        </Col>
                    </Row>
                    <BottomText className="ml-3 mt-4">
                        <Col className="my-auto">
                            <h3><i className="bi bi-trophy-fill"></i> você completou o {props.isModule?"módulo":"curso"}!</h3>
                            <p>Você pode imprimir seu certificado ou salvar para postar nas suas redes sociais.</p>
                        </Col>
                    </BottomText>
                    
                </CertificateContainer>
            )
        }       
       
    </>)
}

export default CertificateCoursePage;



