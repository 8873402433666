import { useEffect, useRef, useCallback } from 'react';
import { Row, Col, Modal, Button } from 'react-bootstrap';
import { useReactToPrint } from 'react-to-print';
import { BottomText, CertificateArea, CertificateContainer } from '../../pages/apprentice/attend/AttentCoursePageStyles';
import { getHolderId } from '../../util/UserUtil';
import { CourseCertificationModalStyles } from './styles';
import { CertificateContent } from "../../pages/apprentice/attend/certificate/CertificateContent";
import certificate_course from './../../assets/certificado-curso-mobile-v2.png'

export function CourseCertificationModal(props: any) {

    const certificateRef = useRef(null)

    const reactToPrintContent = useCallback(() => {
        return certificateRef.current;
    }, [certificateRef.current]);

    const print = useReactToPrint(
        {
            content: reactToPrintContent
        }
    )

    return (
        <CourseCertificationModalStyles
            {...props}   
            centered
            size="xl"
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    <h3 className="d-none d-md-block">Certificado de Conclusão</h3>
                    <section className="modal-header-mobile">
                        <h2 className="d-block d-md-none">Certificado de Conclusão</h2>
                    </section>
                    <BottomText className="modal-bottom-txt mt-4">
                        <Col className="my-auto">
                            <h3><i className="bi bi-trophy-fill"></i> Parabéns por ter finalizado o curso!</h3>
                            <p>Você pode fazer o download do seu certificado clicando no botão na área cinza.</p>
                        </Col>
                    </BottomText>
                </Modal.Title>                
            </Modal.Header>
            <Modal.Body>
                <Row>
                    
                </Row>    
                <CertificateContainer className="d-none d-md-block px-0">
                    <CertificateArea className="px-5">
                        <CertificateContent
                            ref={certificateRef}
                            certificateDetails={props.certificateViewModel}
                            holderId={getHolderId()}
                            isModule={props.isModule}
                            customCertificate={props.customCertificate}
                        />
                    </CertificateArea>
                    <Row className="download">
                        <Col md="auto" className="ml-auto my-auto">
                            <Button onClick={() => print!()}>
                                <i className="bi bi-download" />
                            </Button>
                        </Col>
                    </Row>
                </CertificateContainer>
                <img className="d-block d-md-none" src={certificate_course} />
            </Modal.Body>
        </CourseCertificationModalStyles>
    );
}